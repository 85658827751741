
  export default {
    // Endpoints
    loginEndpoint: `${process.env.VUE_APP_AUTH_API_BASE_URL}/api/auth/login`,
    refreshTokenEndpoint: `${process.env.VUE_APP_AUTH_API_BASE_URL}/api/auth/refresh`,
    meEndpoint: `${process.env.VUE_APP_AUTH_API_BASE_URL}/api/auth/me`,
    
    // Authorization header configuration
    tokenType: 'Bearer',
    
    // Storage keys for tokens
    storageTokenKeyName: 'accessToken',
    storageTokenExpirationDate: 'accessTokenExpirationDate',
}
  